<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="price">
        <div class="price_tit">
            <div>
                供应商
                <el-tooltip class="item" effect="dark" :content="dangqian_gongying_shang.map(item=>item.supply_name).join(',')" placement="top-start">
                    <p @click="dianji_xuanze_gongying_shang(null)">{{dangqian_gongying_shang.length!=0?(dangqian_gongying_shang.map(item=>item.supply_name).join(',')):'请选择'}}</p>
                </el-tooltip>
            </div>
            <div>
                产品
                <el-tooltip class="item" effect="dark" :content="dangqian_xuanze_chanpin.map(item=>item.prod_name).join(',')" placement="top-start">
                    <p @click="dianji_xuanze_chanpin">{{dangqian_xuanze_chanpin.length!=0?(dangqian_xuanze_chanpin.map(item=>item.prod_name).join(',')):'请选择'}}</p>
                </el-tooltip>
            </div>
            <div>
                仓库
                <el-tooltip class="item" effect="dark" :content="dangqian_xuanze_cangku.map(item=>item.label).join(',')" placement="top-start">
                    <p @click="dianji_xuanze_cangku">{{dangqian_xuanze_cangku.length!=0?(dangqian_xuanze_cangku.map(item=>item.label).join('-')):'请选择'}}</p>
                </el-tooltip>
            </div>
            <p @click="daochu">导出</p>
            <p @click="zheng_daoxu=!zheng_daoxu">{{zheng_daoxu?'倒序':'正序'}}</p>
            <p @click="dangqian_zhuangtai_num=2" :class="dangqian_zhuangtai_num==2?'zise':''">看自己</p>
            <p v-if="guanli" @click="dangqian_zhuangtai_num=1" :class="dangqian_zhuangtai_num==1?'zise':''">看员工</p>
            <p v-if="guanli" @click="dangqian_zhuangtai_num=3" :class="dangqian_zhuangtai_num==3?'zise':''">看全部</p>
        </div>
        <div class="gongying_shang_table">
            <el-table
                ref="gongying_shang_ref"
                :data="gongying_shang_list"
                :cell-style="liebiao"
                :header-cell-style="biaotou"
                tooltip-effect="dark"
                height="6.5rem"
                @row-click='hangdian'
                style="width: 100%;font-size: 14px">
                <el-table-column v-for="(i,index) in biao1_tit" :key="index"
                    :prop="i.con"
                    :label="i.name"
                    min-width="150"
                    >
                    <template slot-scope="props">
                        <span :style="i.con=='quoted_price'?(props.row['color']!='nocolor'&&props.row['color']!=undefined?(props.row['color']==2?'padding:0.03rem 0.06rem;background:#6abfa7;border-radius:0.04rem:':'padding:0.03rem 0.06rem;background:#f96c6c;border-radius:0.04rem'):''):''">
                            {{i.con=='quoted_price'?(props.row[i.con]!=undefined&&props.row[i.con].length!=0?props.row[i.con]:'暂无数据'):((props.row[i.con]!=undefined&&props.row[i.con].length!=0?props.row[i.con]:'—'))}}
                        </span>
                        <!-- quoted_price -->
                    </template>
                </el-table-column>
                <!-- <el-table-column v-for="(i,index) in biao2_tit" :key="index"
                    :label="i.name"
                    min-width="150"
                    >
                    <template slot-scope="props">{{props.row.quo_list.length!=0?props.row.quo_list[index].quoted_price:''}}
                        <span v-if="props.row.quo_list.length==0">—</span>
                    </template>
                </el-table-column> -->
            </el-table>
        </div>
        <el-pagination
            @size-change="dianji_tiaoshu"
            @current-change="dianji_yeshu"
            :current-page="dangqian_yeshu"
            class="fenye_qi"
            :page-sizes="[10, 20, 30, 40,50,60,70,80,90,100]"
            :page-size='tiaoshu'
            layout="total,sizes, prev, pager, next, jumper"
            :total="dangqian_zongshu">
        </el-pagination>
        <shaixuan ref="xuanze" @sousuo='sousuo_chanpin' @dalei='dianji_dalei' @shaixuan_queding='dianji_queding'></shaixuan>
        <zujianDuoxuan ref="cangku" @shuxing='chuanzhi'></zujianDuoxuan>
        <div class='beijing' v-if='chanpin' @click="chanpin=false">
            <div class="beijing_box" @click.stop>
                <i class="el-icon-error guanbi_xuanze_chanpin" @click="chanpin=false"></i>
                <div class='beijing_box_tit'>
                    产品分类：
                    <div class="beijing_box_tit_div">
                        <p class="beijing_box_tit_p">{{dangqian_chanpin.label}}</p>
                        <div class="beijing_box_tit_tree">
                            <el-tree :data="qiye_chanpin_leixing_tree" :props="tree_guize" @node-click="xuanze_chanpin_dalei"></el-tree>
                        </div>
                    </div>
                    搜索：
                    <p class="shiyong_sousuo_chanpin">
                        <i class="el-icon-search"></i>
                        <input v-model="sousuo_chanpin_text" @keyup.enter="chanpin_sousuo()" type="text">
                        <i @click="sousuo_chanpin_text='',chanpin_sousuo()" class='el-icon-circle-close'></i>
                    </p>
                    <span class="shiyong_chanpin_queding" @click="dianji_xuanze_chanpin_baocun()">确定</span>
                </div>
                <ul class="mingxi_chanpin_yangshi">
                    <li v-for='(cp,ind) in dangqian_mingxi_kuang_chanpin' :key="cp.prod_code">
                        <i @click="shanchu_yixuan_chanpin(cp,ind)" class="el-icon-error"></i>
                        {{cp.prod_name}}
                    </li>
                </ul>
                <div class='beijing_box_table'>
                        <!-- @selection-change="dianji_xuanzhong_chanpin" -->
                    <el-table :cell-style="liebiao_yangshi" height="500" :header-cell-style="biaotou_yangshi" :data="chanpin_liebiao_list" 
                        highlight-current-row 
                        ref="Tablea" 
                        style="width: 100%" 
                        @select = "xuanze_chanpin"
                        @select-all = "xuanze_chanpin_quanxuan"
                        >
                        <el-table-column  type="selection" width="42">
                        </el-table-column>
                        <el-table-column  prop="prod_code" label="产品编号">
                        </el-table-column>
                        <el-table-column  prop="prod_name" label="产品">
                        </el-table-column>
                        <el-table-column prop="cat_name"  label="类型">
                        </el-table-column>
                        <el-table-column  prop="spec" label="规格">
                        </el-table-column>
                        <el-table-column  prop="type" label="型号">
                        </el-table-column>
                        <el-table-column  prop="unit" label="单位">
                        </el-table-column>
                        <el-table-column  prop="price_basic" label="初始价">
                        </el-table-column>
                        <!-- <el-table-column  prop="qty" label="数量">
                        </el-table-column> -->
                        <!-- <el-table-column  prop="price_cost" label="成本" width="80">
                        </el-table-column>
                        <el-table-column  prop="price_sale" label="售价" width="80">
                        </el-table-column> -->
                        <el-table-column  prop="disc_rate" label="调价率">
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import shaixuan from '../../../components/shaixuan/Duoxuan'
import zujianDuoxuan from '../../../components/zujian/Tree'
import { query_all_prod_list_of_category, query_ent_prod_cat_list_tree, query_user_info, query_ent_site_tree, query_category_info_list, query_prod_info_list, get_prod_list_like, query_supply_list, query_compare_price_list } from '../../../api/api.js'
export default {
  name: 'price',
  data () {
    return {
      gongying_shang_list: [],
      biao1_tit: [
        {
          name: '产品',
          con: 'prod_name'
        },
        {
          name: '产品编号',
          con: 'prod_code'
        },
        {
          name: '规格',
          con: 'spec'
        },
        {
          name: '型号',
          con: 'type'
        },
        {
          name: '单位',
          con: 'unit'
        },
        {
          name: '仓库',
          con: 'site_name'
        },
        // {
        //     name:'货位号',
        //     con:'site_no',
        // },
        {
          name: '供应商',
          con: 'supply_name'
        },
        {
          name: '报价(元)',
          con: 'quoted_price'
        }
      ],
      dangqian_yeshu: 1,
      dangqian_zongshu: 0,
      tiaoshu: 10,
      biao2_tit: [],

      dangqian_xuanze_chanpin: [], // 当前所选的产品id
      dangqian_gongying_shang: [], // 当前所选的供应商id
      dangqian_xuanze_cangku: [], // 当前所选的仓库
      zheng_daoxu: true, // 正倒序  true正序   false倒序

      dangqian_zhuangtai_num: '2',
      guanli: false,
            
      // url:'https://cbr.mlcbr.com:8443/hwb_client/V1.0.0/',//服务器2号机
      // url:'https://www.mlcbr.com:8443/hwb_client/V1.0.0/',
      url: 'https://cbr.mlcbr.com:8443/hwb_client/V1.0.0/',
            
      // 产品选择修改选择样式    2020、11、11   啊哈哈  光棍节快乐啊QwQ
      chanpin: false,
      dangqian_chanpin: {
        label: '',
        id: ''
      },
      qiye_chanpin_leixing_tree: [], // 产品大类树
      sousuo_chanpin_text: '', // 搜索产品text
      chanpin_xuanze: '',
      chanpin_liebiao_list: [],
      tree_guize: {
        children: 'children',
        label: 'label'
      },
      dangqian_mingxi_kuang_chanpin: []
    }
  },
  mounted () {
  },
  components: { shaixuan, zujianDuoxuan },
  created () {
    this.chaxun_quanxian()
    this.liebiao_jiekou()
  },
  watch: {
    dangqian_yeshu () {
      // sessionStorage.setItem('cbr_yifen_pei', JSON.stringify(this.dangqian_yeshu))
      this.liebiao_jiekou()
    },
    tiaoshu () {
      this.liebiao_jiekou()
    },
    dangqian_xuanze_chanpin () {
      this.liebiao_jiekou()
    },
    dangqian_gongying_shang () {
      this.liebiao_jiekou()
    },
    dangqian_xuanze_cangku () {
      this.liebiao_jiekou()
    },
    zheng_daoxu () {
      this.liebiao_jiekou()
    },
    dangqian_zhuangtai_num () {
      this.dangqian_yeshu = 1
      this.tiaoshu = 10
      this.liebiao_jiekou()
    },
    chanpin () {
      if (!this.chanpin) {
        this.dangqian_chanpin = {
          label: '',
          id: ''
        }
        this.sousuo_chanpin_text = ''
        this.chanpin_xuanze = ''
        this.chanpin_liebiao_list = []
        this.dangqian_mingxi_kuang_chanpin = []
      }
    }
  },
  methods: {
    // 表头颜色
    biaotou (row) { // 根据报警级别显示颜色
      return 'text-align:center;font-size:0.14rem;color:#1A2533;'
    },
    // color:#4C4A4D;font-size:0.12rem;
    liebiao (row) {
      console.log(row.column.label)
      if (row.column.label == '报价(元)') {
        return 'text-align:right;color:#1A2533;padding-right:0.3rem;'
      } else {
        return 'text-align:center;color:#1A2533;'
      }
    },
    hangdian (val) {
      console.log(val)
    },
    dianji_yeshu (val) {
      this.dangqian_yeshu = val
    },
    dianji_tiaoshu (val) {
      console.log(`每页 ${val} 条`)
      this.tiaoshu = val
      this.dangqian_yeshu = 1
    },
    liebiao_jiekou () {
      query_compare_price_list({
        data: {
          ent_id: this.$ent_id(),
          prod_ids: this.dangqian_xuanze_chanpin.length != 0 ? this.dangqian_xuanze_chanpin.map(item => item.prod_id).join(',') : null, // 选择产品id
          page_size: this.tiaoshu + '',
          page_index: this.dangqian_yeshu + '',
          site_ids: this.dangqian_xuanze_cangku.length != 0 ? this.dangqian_xuanze_cangku.map(item => item.id).join(',') : null, // 选择仓库id
          supply_ids: this.dangqian_gongying_shang.length != 0 ? this.dangqian_gongying_shang.map(item => item.supply_id).join(',') : null, // 选择供应商id,
          sort_type: this.zheng_daoxu ? '1' : '2',
          look_type: this.dangqian_zhuangtai_num + '',
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          if (date.list.length != 0) {
            this.dangqian_zongshu = date.total
            this.gongying_shang_list = date.list
            this.gongying_shang_list.forEach(item => {
              if(item.quoted_price === 'null' || item.quoted_price === null) {
                item.quoted_price = '-'
              }
            })
            // quoted_price  //比价
            // let list=[]
            // date.list[1].quo_list.forEach((ite,inde)=>{
            //     this.$set(ite,ite.supply_id,ite.quoted_price)
            //     list.push({
            //         name:ite.supply_name,
            //         con:ite.supply_id,
            //     })
            // })
            // this.biao2_tit=list
            // console.log(list)
          } else {
            this.dangqian_zongshu = 0
            this.gongying_shang_list = []
            // this.this.biao2_tit=[]
          }
        } else if (res.data.code == 500) {
          alert('系统报错')
        }
      })
    },
    // 点击选择产品
    dianji_xuanze_chanpin () {
      // this.chanpin_dalei(null)
      this.chanpin = true
      query_ent_prod_cat_list_tree({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.qiye_chanpin_leixing_tree = date
        } else if (res.data.code == 500) {}
      })
      query_prod_info_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          cat_id: '0',
          active: '1'
        }
      }).then(res => {
        if (res.data.code == 10139) {
          const date = JSON.parse(res.data.body.data)
          this.no_xuan_chanpin(date)
        } else if (res.data.code == 10140) {
        }
      })
      this.dangqian_mingxi_kuang_chanpin = this.dangqian_xuanze_chanpin
    },
    chanpin_dalei (parent_id, yixuan) {
      if (yixuan != undefined && yixuan != null) {
      } else {
        yixuan = this.dangqian_xuanze_chanpin
      }
      query_category_info_list({
        data: {
          active: '1',
          ent_id: this.$ent_id(),
          parent_id: parent_id != null ? parent_id : '0',
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10193) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.chanpin_xiaolei(parent_id, date, yixuan)
        } else if (res.data.code == 10194) {}
      })
    },
    chanpin_xiaolei (parent_id, parent, yixuan) {
      query_prod_info_list({
        data: {
          active: '1',
          cat_id: parent_id != null ? parent_id : '0',
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10139) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.$refs.xuanze.jichu('选择产品', parent, date, yixuan, 'cat_name', 'cat_id', 'prod_name', 'prod_id')
          this.$refs.xuanze.bangding('dangqian_xuanze_chanpin')
        } else if (res.data.code == 10140) {}
      })
    },
    sousuo_chanpin (val) {
      if (val.bangding_ziduan == 'dangqian_xuanze_chanpin') {
        get_prod_list_like({
          data: {
            active: '1',
            ent_id: this.$ent_id(),
            prod_name: val.text.length != 0 ? val.text : null,
            user_id: this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10031) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            this.$refs.xuanze.sousuo_xiaolei(date, 'prod_name', 'prod_id')
          } else if (res.data.code == 10032) {}
        })
      } else {
        query_supply_list({
          data: {
            ent_id: this.$ent_id(),
            search_str: val.text.length != 0 ? val.text : null
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            this.$refs.xuanze.sousuo_xiaolei(date, 'supply_name', 'supply_id')
          } else if (res.data.code == 500) {
            alert('查询供应商list报错')
          }
        })
      }
    },
    // 点击大类
    dianji_dalei (data) {
      this.chanpin_dalei(data.id, data.yixuan)
    },
    dianji_queding (val) {
      console.log(val)
      this[val.ziduan] = val.list
    },
    // 点击选择供应商
    dianji_xuanze_gongying_shang (yixuan) {
      if (yixuan != undefined && yixuan != null) {
      } else {
        yixuan = this.dangqian_gongying_shang
      }
      this.gongying_shang_xiaolei(yixuan)
    },
    gongying_shang_xiaolei (yixuan) {
      query_supply_list({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          this.$refs.xuanze.jichu('选择供应商', null, date, yixuan, null, null, 'supply_name', 'supply_id')
          this.$refs.xuanze.bangding('dangqian_gongying_shang')
        } else if (res.data.code == 500) {
          alert('查询供应商list报错')
        }
      })
    },
    // 点击选择仓库
    dianji_xuanze_cangku () {
      query_ent_site_tree({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.$refs.cangku.jichu(date, '仓库选择', this.dangqian_xuanze_cangku, '多选', '有关联')
        } else if (res.data.code == 500) {}
      })
    },
    chuanzhi (val) {
      console.log(val)
      this.dangqian_xuanze_cangku = val
      console.log(this.dangqian_xuanze_cangku)
    },
    chaxun_quanxian () { // 查询权限
      query_user_info({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = res.data.body
          // console.log()
          if (date.staff_level == 1 || date.staff_level == 2 || date.dept_manager == 1) {
            this.guanli = true
          } else {
            this.guanli = false
          }
        }
      })
    },
    daochu () {
      const date = this.url + 'export_supply_quotation_compare?ent_id=' + this.$ent_id() + '&prod_ids=' + (this.dangqian_xuanze_chanpin.length != 0 ? this.dangqian_xuanze_chanpin.map(item => item.prod_id).join(',') : null) + '&supply_ids=' + (this.dangqian_gongying_shang.length != 0 ? this.dangqian_gongying_shang.map(item => item.supply_id).join(',') : null) + '&site_ids=' + (this.dangqian_xuanze_cangku.length != 0 ? this.dangqian_xuanze_cangku.map(item => item.id).join(',') : null) + '&sort_type=' + (this.zheng_daoxu ? '1' : '2') + '&look_type=' + (this.dangqian_zhuangtai_num + '') + '&user_id=' + this.$jichuxinxi().user_id
      this.$func.multiDownLoad([date])
    },
    no_xuan_chanpin (date) {
      this.chanpin_liebiao_list = date
      this.$forceUpdate()
    },
    // 2020、6、10更改产品样式   2020/11/10修改传值
    xuanze_chanpin_dalei (val) {
      console.log(val)
      this.dangqian_chanpin = val
      if (this.dangqian_chanpin.id.length != 0) {
        query_all_prod_list_of_category({
          data: {
            ent_id: this.$ent_id(),
            cat_id: this.dangqian_chanpin.id + ''
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            // let date=JSON.parse(res.data.body.data)
            console.log(this.buneng_xuan_chanpin)
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            this.no_xuan_chanpin(date)
            // var b = this.buneng_xuan_chanpin
            // let deal = (arr1, arr2, key) => {
            //     let arr3 = []
            //     for (let index = 0; index < arr1.length; index++) {
            //         let item = arr1[index]
            //         if (!arr2.some(v => v[key] === item[key])) {
            //             arr3.push(item)
            //         }
            //     }
            //     return arr3
            // }
            // console.log(deal(a,b,'prod_id'))
            // this.chanpin_liebiao_list=deal(a,b,'prod_id')
            // console.log(this.chanpin_liebiao_list)
            // this.$forceUpdate()
            if (this.dangqian_mingxi_kuang_chanpin.length != 0) {
              this.$nextTick(function () {
                this.chanpin_liebiao_list.forEach(item => {
                  this.dangqian_mingxi_kuang_chanpin.forEach(ite => {
                    if (ite.prod_id == item.prod_id) {
                      console.log(item)
                      this.$refs.Tablea.toggleRowSelection(item)
                      // .forEach(i=>{
                      //     i.toggleRowSelection(item)
                      // })
                    }
                  })
                })
              })
            }
            this.$forceUpdate()
          } else if (res.data.code == 500) {}
        })
      }
    },
    chanpin_sousuo () {
      // sousuo_chanpin_text
      if (this.sousuo_chanpin_text.length != 0) {
        get_prod_list_like({
          data: {
            active: '1',
            ent_id: this.$ent_id(),
            prod_name: this.sousuo_chanpin_text.length != 0 ? this.sousuo_chanpin_text : null,
            user_id: this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10031) {
            const date = JSON.parse(res.data.body.data)
            this.no_xuan_chanpin(date)
            // for(let i=0;i<date.length;i++){
            //     for(let a=0;a<this.buneng_xuan_chanpin.length;a++){
            //         if(date[i].prod_id==this.buneng_xuan_chanpin[a]){
            //             date.splice(i,1)
            //             i=i-1
            //         }
            //     }
            // }
            // this.chanpin_liebiao_list=date
            // console.log(this.chanpin_liebiao_list)
            this.$forceUpdate()
          } else if (res.data.code == 10032) {}
        })
      } else {
        if (this.dangqian_chanpin.id.length != 0) {
          query_all_prod_list_of_category({
            data: {
              ent_id: this.$ent_id(),
              cat_id: this.dangqian_chanpin.id + ''
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              this.no_xuan_chanpin(date)
              // console.log(date)
              // console.log(this.buneng_xuan_chanpin)
              // for(let i=0;i<date.length;i++){
              //     for(let a=0;a<this.buneng_xuan_chanpin.length;a++){
              //         if(date[i].prod_id==this.buneng_xuan_chanpin[a]){
              //             date.splice(i,1)
              //             i=i-1
              //         }
              //     }
              // }
              // this.chanpin_liebiao_list=date
              // console.log(this.chanpin_liebiao_list)
              // this.$forceUpdate()
            } else if (res.data.code == 500) {}
          })
        } else {
          this.chanpin_liebiao_list = []
        }
      }
    },
    dianji_xuanze_chanpin_baocun () {
      console.log(this.dangqian_mingxi_kuang_chanpin)
      console.log(this.suoxuan_list)
      this.dangqian_xuanze_chanpin = this.dangqian_mingxi_kuang_chanpin
      this.chanpin = false
    },
    liebiao_yangshi (row) {
      return 'color:#4C4A4D;font-size:0.1rem;'
    },
    biaotou_yangshi (row) {
      return 'color:#1A2533;'
    },
    // dianji_xuanzhong_chanpin(val){
    //     this.chanpin_xuanze = val
    // },
    xuanze_chanpin (rows, row) {
      console.log(rows)
      console.log(row)
      const ses = rows.length && rows.indexOf(row) !== -1 // 判断是否打钩
      if (this.dangqian_mingxi_kuang_chanpin.length != 0) {
        if (ses) {
          this.dangqian_mingxi_kuang_chanpin.push(row)
        } else {
          for (let i = 0; i < this.dangqian_mingxi_kuang_chanpin.length; i++) {
            if (this.dangqian_mingxi_kuang_chanpin[i].prod_id == row.prod_id) {
              this.dangqian_mingxi_kuang_chanpin.splice(i, 1)
              i = i - 1
            }
          }
        }
      } else {
        this.dangqian_mingxi_kuang_chanpin = rows
      }
      // return rows.length && rows.indexOf(row) !== -1
    },
    xuanze_chanpin_quanxuan (rows, row) {
      console.log(rows)
      if (rows.length != 0) {
        rows.forEach(item => {
          this.dangqian_mingxi_kuang_chanpin.push(item)
        })
        this.dangqian_mingxi_kuang_chanpin = this.$func.Es5duplicate(this.dangqian_mingxi_kuang_chanpin, 'prod_id')
      } else {
        // chanpin_liebiao_list
        // this.dangqian_mingxi_kuang_chanpin
        for (let i = 0; i < this.dangqian_mingxi_kuang_chanpin.length; i++) {
          // this.chanpin_liebiao_list.forEach(item=>{
          for (let a = 0; a < this.chanpin_liebiao_list.length; a++) {
            if (this.dangqian_mingxi_kuang_chanpin[i].prod_id == this.chanpin_liebiao_list[a].prod_id) {
              this.dangqian_mingxi_kuang_chanpin.splice(i, 1)
              i = i - 1
              break
            }
          }
          // })
        }
      }
    },
    shanchu_yixuan_chanpin (i, index) {
      this.chanpin_liebiao_list.forEach(ite => {
        if (ite.prod_id == i.prod_id) {
          this.$refs.Tablea.toggleRowSelection(ite)
        }
      })
      this.dangqian_mingxi_kuang_chanpin.splice(index, 1)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
 @import '../chanpin.scss';
</style>
